<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
                <v-flex lg12 md12 sm12 xs12 pl-6 pt-6 pr-6>
                    <v-layout row wrap justify-end>
                        <v-menu v-model="menuDate" :close-on-content-click="false"  transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                :disabled="currentRecharge.ID > 0 && !adminRole"
                                v-model="computedDateFormatted"
                                label="Ngày"
                                hint="DD-MM-YYYY"
                                persistent-hint
                                readonly
                                v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="chargeDate" no-title @input="menuDate = false"></v-date-picker>
                        </v-menu>
                    </v-layout>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-autocomplete :disabled="currentRecharge.ID > 0" label="Khách hàng" v-model="currentRecharge.UID" :items="customers" item-text="Username" item-value="ID"></v-autocomplete>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <h-currency-input :disabled="currentRecharge.ID > 0" v-model="currentRecharge.Amount" :decimal="0" label="Số tiền" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-select label="Hình thức" :disabled="currentRecharge.ID > 0" v-model="currentRecharge.Method" :items="rechargeMethods" item-text="name" item-value="value"></v-select>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-textarea :disabled="currentRecharge.ID > 0" label="Ghi chú" v-model="currentRecharge.TradeContent" class="input-group--focused"></v-textarea>
                </v-flex>
                <v-layout class="d-flex flex-row" v-if="!currentRecharge.ID">
                    <v-flex lg7 md7 sm7>
                        <v-btn block color="#a77800" dark @click="saveData">
                            Nạp tiền
                        </v-btn>
                    </v-flex>
                    <v-flex lg5 md5 sm5 pr-8>
                        <v-btn block color="second" dark @click="clearData">
                            Hủy lệnh
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-card>
    </v-container>
    <div class="page-header-left pl-3 pt-5">
        <h3>Lịch sử nạp tiền</h3>
    </div>
    <recharge-list :needRefesh="needRefesh"></recharge-list>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import configs from "@/configs";
import {
    sendErrorNotice
} from '@/commons/utils';
import recharge_list from "./list.vue";
import moment from "moment";
export default {
    components: {
        "recharge-list": recharge_list
    },
    data() {
        return {
            recharge_status: configs.RECHARGE_STATUS,
            recharge_status_list: configs.RECHARGE_STATUS_LIST,
            rechargeMethods: configs.RECHARGE_METHODS,
            editStatus: 1,
            needRefesh: false,
            menuDate: false,
            chargeDate: moment.utc(Date.now()).toISOString().substr(0, 10),
            currentDate: moment.utc(Date.now()).toISOString().substr(0, 10)
        };
    },
    computed: {
        ...mapState({
            customers: state => state.customer.master.data,
            loading: state => state.rechargeHistory.loading,
            currentRecharge: state => state.rechargeHistory.selected,
        }),
        rechargeId() {
            return this.$route.params.Pid;
        },
        computedDateFormatted () {
            return this.currentRecharge && this.currentRecharge.CreatedDate && this.currentRecharge.ID > 0 ? moment.utc(this.currentRecharge.CreatedDate).format(configs.SHORT_DATE_FORMAT) : moment.utc(this.chargeDate).format(configs.SHORT_DATE_FORMAT);
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
        csRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
        },
    },
    watch: {
        currentRecharge() {
            if (this.rechargeId > 0) {
                this.editStatus = this.currentRecharge.Status;
            } else {
                this.editStatus = this.recharge_status.Approved;
            }
            if(this.currentRecharge && this.currentRecharge.CreatedDate > 0) {
                this.chargeDate = moment.utc(this.currentRecharge.CreatedDate).toISOString().substr(0, 10)
            } 
        },
        rechargeId() {
            this.refreshData();
        }
    },
    methods: {        
        refreshData() {
            this.getCustomerList();
            if (this.rechargeId > 0) {
                this.$store.dispatch("rechargeHistory/getDetail", this.rechargeId);
            }
            else {
                this.clearData();
            }
        },
        clearData() {
            if (!this.rechargeId) {
                this.$store.commit("rechargeHistory/setDetail", {
                    data: {
                        ID: 0,
                        UID: 0,
                        Method: 4,
                        Username: '',
                        Amount: 0,
                        Status: this.recharge_status.Approved,
                        TradeContent: ''
                    }
                });
            }
        },
        saveData() {
            this.needRefesh = false;
            if (this.currentRecharge.Amount <= 0 || this.currentRecharge.UID === 0) {
                sendErrorNotice(`Hãy nhập số tiền và user muốn nạp`);
                return;
            }
            if (this.currentRecharge.ID > 0 && this.currentRecharge.Status === this.editStatus) {
                if(this.adminRole && this.currentDate != this.chargeDate) {
                     this.$store.dispatch("rechargeHistory/setDetail", {
                        data: {
                            CreatedDate: moment(this.chargeDate).format(configs.SQL_DATE_FORMAT)
                        },
                        id: this.currentRecharge.ID
                    });
                }
                return;
            }
            this.currentRecharge.Status = this.editStatus;
            let customer = _.find(this.customers, {
                ID: this.currentRecharge.UID
            });
            if (customer) {
                this.currentRecharge.RechargeID = this.$store.state.authentication.user.userid;
                this.currentRecharge.Username = customer.Username;
                this.$store.dispatch("rechargeHistory/setDetail", {
                    data: {
                        ...this.currentRecharge,
                        CreatedDate: this.currentDate != this.chargeDate ? moment(this.chargeDate).format(configs.SQL_DATE_FORMAT) : null
                        },
                    id: this.currentRecharge.ID
                });
                if (!this.currentRecharge.ID || this.currentRecharge.ID === 0) {
                    this.$store.commit("rechargeHistory/setDetail", {
                        data: {
                            ID: 0,
                            UID: 0,
                            Method: 4,
                            Username: '',
                            Amount: 0,
                            Status: this.csRole ? this.recharge_status.Pending : this.recharge_status.Approved,
                            TradeContent: '',
                            CreatedDate: moment.utc(Date.now()),
                        }
                    });
                }
                this.needRefesh = true;
            } else {
                sendErrorNotice('Không tìm thấy khách hàng.')
            }

        },
        getCustomerList() {
            this.$store.dispatch("customer/getCustomerList", {
                is_master: 1,
                fields: ["ID", "Username"]
            });
        },
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        if (this.$route.query.uid && this.$route.query.uid !== '') {
            this.currentRecharge.UID = Number(this.$route.query.uid);
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>

<style>
</style>
